import { ThumbnailProps } from 'core/thumbnail/thumbnail.props';
import { theme } from 'styles/theme';

export const ThumbnailPresets: ThumbnailProps = {
  size: '5rem',
  border: `1px solid ${theme.colors.cream}`,
  borderRadius: '3px',
  objectFit: 'contain',
  alt: '',
};
