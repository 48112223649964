import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { TypographyWithIconProps } from 'core/typography-with-icon/typography-with-icon.props';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';

export const StyledTypographyWithIcon = styled.div<
  Partial<TypographyWithIconProps>
>`
  ${({ gap }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'column-gap',
        resolutionAwareProp: gap,
      },
    ];

    return css`
      display: grid;
      grid-template-columns: 1fr max-content;
      align-content: center;
      align-items: center;
      max-width: max-content;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;
