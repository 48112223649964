import React from 'react';
import { TypographyWithIconProps } from 'core/typography-with-icon/typography-with-icon.props';
import { TypographyWithIconPresets } from 'core/typography-with-icon/typography-with-icon.presets';
import { StyledTypographyWithIcon } from 'core/typography-with-icon/typography-with-icon.styles';
import { Typography } from 'core/typography';

export const TypographyWithIcon = (props: TypographyWithIconProps) => {
  const { icon, iconPosition, gap, ...others } = Object.assign(
    {},
    TypographyWithIconPresets,
    props,
  );

  return (
    <StyledTypographyWithIcon gap={gap}>
      {iconPosition === 'left' && icon}
      <Typography {...others} />
      {iconPosition === 'right' && icon}
    </StyledTypographyWithIcon>
  );
};
