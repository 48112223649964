import React from 'react';
import { ThumbnailProps } from 'core/thumbnail/thumbnail.props';
import { ThumbnailPresets } from 'core/thumbnail/thumbnail.presets';
import { StyledThumbnail } from './thumbnail.styles';

export const Thumbnail = (props: ThumbnailProps) => {
  const { src, alt, ...others } = Object.assign({}, ThumbnailPresets, props);

  return <StyledThumbnail src={src} alt={alt} {...others} />;
};
